import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { Flex, PseudoBox, Image } from '@chakra-ui/core';

import FileUpload from '../../../../../components/Form/FileUpload';
import { useProductForm } from '../../../contexts/ProductFormProvider';

interface IProps {
  variantName: string;
  defaultImage?: any;
}

const UploadVariantImage: React.FC<IProps> = ({
    variantName,
    defaultImage = undefined
}) => {
    const [uploadedImage, setUploadedImage] = useState(defaultImage);
    const { editVariant } = useProductForm();

    const onUploadImage = useCallback(
        imageUploaded => {
            setUploadedImage(imageUploaded);

            console.log(imageUploaded);

            if (imageUploaded.uploaded === true) {
                editVariant(variantName, 'image_id', imageUploaded.id);
            }
        },
        [variantName, editVariant]
    );

    useEffect(() => {
        if (defaultImage) {
            setUploadedImage({
                id: defaultImage.id,
                uploaded: true,
                preview: defaultImage.image_url
            });
        }
    }, [defaultImage]);

    return !uploadedImage ? (
        <FileUpload width="100%" height="100%" setUploadedImage={onUploadImage} />
    ) : (
        <Flex
            flexDirection="column"
            alignItems="center"
            mr="16px"
            position="relative"
        >
            <PseudoBox
                mt="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="96px"
                height="72px"
                _hover={{
                    cursor: 'pointer',
                    color: 'green.500'
                }}
            >
                <Flex
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    {!uploadedImage.uploaded && !uploadedImage.error && (
                        <CircularProgressbar
                            styles={{
                                root: { width: 48 },
                                path: { stroke: '#1EBD94' }
                            }}
                            strokeWidth={10}
                            value={uploadedImage.progress}
                        />
                    )}

                    {uploadedImage.uploaded && <Image src={uploadedImage.preview} />}
                </Flex>
            </PseudoBox>
        </Flex>
    );
};

export default UploadVariantImage;

import React, { useRef, useEffect, useCallback, useState } from 'react';

import { Flex, Text, InputProps, Textarea } from '@chakra-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { useField } from '@unform/core';

import InputBadgeError from '../InputBadgeError';

interface IProps extends InputProps<HTMLTextAreaElement> {
  name: string;
  label?: string;
  isRequired?: boolean;
  formatChars?: {
    [key: string]: string;
  };
  badgeErrorColor?: string;
  mb?: string | number;
}

const TextEditor: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    badgeErrorColor = 'red',
    defaultValue = ''
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState('');

    const { fieldName, error, registerField } = useField(name);

    const handleChange = useCallback(newValue => {
        console.log(newValue);
        setValue(newValue.level.content);
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue as string);
        }

        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [defaultValue, fieldName, registerField]);

    return (
        <Flex width="100%" flexDirection="column" fontWeight="500" mb="64px">
            {label && (
                <Flex width="100%" textAlign="center" mb="4px">
                    <Text>{label}</Text>
                    {isRequired && (
                        <Text ml="8px" color="red.500">
              *
                        </Text>
                    )}
                </Flex>
            )}

            <Flex width="100%" flexDirection="column">
                <Editor
                    textareaName={name}
                    apiKey="9qa5qykx07kskrltsx8g7whfx6lb9ntfsbilaad579902xo9"
                    onInit={(evt, editor: any) => null}
                    initialValue={(defaultValue as any) || ''}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | table link image | ' +
              'code code-block | help',
                        content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        images_upload_url:
              'https://app.eflorista.com.br/api/v1/pages/upload',

                        image_uploadtab: true
                    }}
                    onChange={handleChange}
                />
                <Textarea ref={inputRef} display="none" name={name} value={value} />
                {error && <InputBadgeError error={error} color={badgeErrorColor} />}
            </Flex>
        </Flex>
    );
};

export default TextEditor;

import React, { useCallback, useEffect, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Badge, Divider, Flex, PseudoBox, Text } from '@chakra-ui/core';

import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';

const DeliveryMethods: React.FC = () => {
    const { changeTitle } = useLayout();
    const navigate = useNavigate();

    const [deliveryMethods, setDeliveryMethods] = useState([]);

    const routes = ['delivery_districts', 'delivery_cities'];

    const handleChange = useCallback(async district => {
        setDeliveryMethods(oldState => {
            return oldState.map(item => {
                if (item.id === district.id) {
                    return {
                        ...district,
                        is_enable: !district.is_enable
                    };
                }

                return item;
            });
        });

        await apiGateway.put(`/settings/delivery_methods/${district.id}`, {
            is_enable: !district.is_enable
        });
    }, []);

    const DeliveryMethodRow = ({ style, index, data }) => {
        return (
            <ListRow style={style} index={index}>
                <Flex width="100%" justifyContent="center" maxWidth="40px" />
                <Flex width="100%" justifyContent="center">
                    <Text textAlign="center" lineHeight="16px">
                        {data[index].name}
                    </Text>
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Badge
                        width="100%"
                        maxWidth="160px"
                        textAlign="center"
                        backgroundColor={data[index].is_enable ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                        onClick={() => handleChange(data[index])}
                        cursor="pointer"
                    >
                        {data[index].is_enable ? 'Habilitado' : 'Desabilitado'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        title="Configurações"
                        py="8px"
                        px="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        textAlign="center"
                        onClick={() => {
                            navigate(`/settings/delivery_methods/${routes[index]}`);
                        }}
                    >
                        <FiSettings size={16} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Métodos de Entrega');
        apiGateway.get('/settings/delivery_methods').then(response => {
            setDeliveryMethods(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Formas de Entrega</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Situação</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {deliveryMethods.length > 0 && (
                        <List item={deliveryMethods} row={DeliveryMethodRow} />
                    )}

                    {deliveryMethods.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default DeliveryMethods;

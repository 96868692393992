import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Divider, Flex, Text } from '@chakra-ui/core';

import Button from '../../../../components/Button';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';
import DateUtils from '../../../../shared/utils/DateUtils';

const DeliverymensListPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [users, setDeliverymens] = useState([]);
    const [selectedDeliverymensIds, setSelectedDeliverymensIds] = useState([]);
    const handleChangeSelectedDeliverymensIds = useCallback(
        (input: any) => {
            let list = selectedDeliverymensIds;

            if (input.checked) {
                if (!selectedDeliverymensIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            setSelectedDeliverymensIds(list);
        },
        [selectedDeliverymensIds]
    );

    const handleDeleteDeliverymens = useCallback(() => {
        apiGateway
            .delete('/users', {
                params: {
                    ids: selectedDeliverymensIds
                }
            })
            .then(response => {
                if (response.status === 200) {
                    const updatedList = users.filter(
                        (city: any) => !selectedDeliverymensIds.includes(city.id as never)
                    );

                    setDeliverymens(updatedList);
                }
            });
    }, [users, selectedDeliverymensIds]);

    useEffect(() => {
        changeTitle('Lista de Entregadores');
        apiGateway.get('/users/deliverymens').then(response => {
            setDeliverymens(response.data);
        });
    }, [changeTitle]);

    const UserRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e => handleChangeSelectedDeliverymensIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].telephone}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].permission || 'Admin'}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{DateUtils.format(data[index].created_at, 'dd/MM/yy')}</Text>
                </Flex>
            </ListRow>
        );
    };

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/users/deliverymens/register')}
                >
                    Novo Entregador
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir DeliverymensList"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteDeliverymens()}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Telefone</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Permissão</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Criado em</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {users.length > 0 && (
                        <List item={users} row={UserRow} itemHeight={48} />
                    )}

                    {users.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default DeliverymensListPage;

import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Text, useToast, Image } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import checkSuccess from '../../../assets/images/check-success.png';
import logo from '../../../assets/images/wsuite-logo.png';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/Form/TextArea';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';
import UploadDeliveryImage from '../components/UploadDeliveryImage';

const DeliveryIndex: React.FC = () => {
    const formRef: any = useRef<FormHandles>();

    const toast = useToast();

    const { storeId, orderId } = useParams() as any;

    const [isDestinatary, setIsDestinatary] = useState(true);

    const [isSucessSubmit, setIsSuccessSubmit] = useState(false);

    const [dataUri, setDataUri] = useState('');

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.put(
                    `/orders/delivery/${storeId}/${orderId}`,
                    {
                        is_destinatary: isDestinatary,
                        ...formData,
                        photo_url: dataUri
                    }
                );

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess('Envio Realizado', '', toast);
                    setIsSuccessSubmit(true);
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [formRef, storeId, orderId, isDestinatary, dataUri]
    );

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            {!isSucessSubmit && (
                <Flex width="100%" flexDirection="column" alignItems="center">
                    <Flex
                        width="100%"
                        height="72px"
                        backgroundColor="#42478B"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logo} width="64px" height="40px" />
                    </Flex>

                    <Flex
                        width="100%"
                        color="#7A7878"
                        justifyContent="center"
                        fontWeight="600"
                        mt="8px"
                    >
                        <Text>Pedido</Text>
                        <Text ml="8px">{orderId}</Text>
                    </Flex>

                    <Flex
                        width={['312px', '360px', '400px']}
                        color="#363636"
                        flexDirection="column"
                        justifyContent="center"
                        mb="16px"
                    >
                        <Text fontWeight="600">Entrega em Mãos do destinatário(a)?</Text>
                        <Flex>
                            <Checkbox
                                name="is_promotional"
                                isChecked={isDestinatary === false}
                                onChange={() => setIsDestinatary(!isDestinatary)}
                                value="0"
                                mr="16px"
                                size="lg"
                            >
                                <Text fontSize="14px">Não</Text>
                            </Checkbox>
                            <Checkbox
                                name="is_promotional"
                                isChecked={isDestinatary}
                                onChange={() => setIsDestinatary(!isDestinatary)}
                                value="1"
                                mr="16px"
                                size="lg"
                            >
                                <Text fontSize="14px">Sim</Text>
                            </Checkbox>
                        </Flex>
                    </Flex>

                    <Flex
                        width={['312px', '360px', '400px']}
                        color="#363636"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Flex display={isDestinatary === false ? 'flex' : 'none'}>
                            <Input
                                name="receiver_name"
                                label="Nome de quem recebeu"
                                size="sm"
                                mb="4px"
                            />
                        </Flex>

                        <TextArea
                            name="observations"
                            label="Observação"
                            size="sm"
                            mb="4px"
                        />
                    </Flex>

                    <Flex width="100%" justifyContent="center" my="24px">
                        <UploadDeliveryImage setFunction={setDataUri} />
                    </Flex>

                    <Button
                        type="submit"
                        width={['312px', '360px', '400px']}
                        backgroundColor="#F2B502"
                        height="48px"
                        mt="8px"
                        borderRadius="4px"
                        mb="24px"
                    >
                        <Flex fontSize="14px">
                            <Text>Confirmar</Text>
                        </Flex>
                    </Button>
                </Flex>
            )}

            {isSucessSubmit && (
                <Flex width="100vw" height="100vh" flexDirection="column">
                    <Flex
                        width="100%"
                        height="72px"
                        backgroundColor="#42478B"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logo} width="64px" height="40px" />
                    </Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Image width="120px" height="120px" src={checkSuccess} />

                        <Text fontWeight="500" mt="8px">
              Confirmação Enviada
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Form>
    );
};

export default DeliveryIndex;

import axios from 'axios';

import { HTTP } from '../constants';

const apiGateway = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    validateStatus: (status: number) => {
        return status >= 200 && status <= 400;
    }
});

apiGateway.interceptors.request.use(req => {
    const token = localStorage.getItem('@eflorista:token');
    if (token)
        req.headers['Authorization'] = `Bearer ${token}`;
    return req;

}, error => Promise.reject(error));

apiGateway.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === HTTP.STATUS.NOT_AUTORIZED) {
            localStorage.removeItem('@eflorista:token');
            localStorage.removeItem('@eflorista:user');
            window.location.href = '/login';
        }

        return error;
    }
);

export default apiGateway;
 
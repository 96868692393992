import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import { HTTP } from '../../../shared/constants';
import apiGateway from '../../../shared/services/apiGateway';
import UploadBannerImage from '../components/UploadBannerImage';
import BannerFormHelper from '../helpers/BannerFormHelper';

const BannersRegister: React.FC = () => {
    const formRef = useRef<FormHandles>();

    const [bannerWeb, setBannerWeb] = useState(null);
    const [bannerMobile, setBannerMobile] = useState(null);

    const [isEnable, setEnable] = useState(false);
    const navigate = useNavigate();

    const toast = useToast();

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post('/banners', {
                    type: formData.type,
                    name: formData.name,
                    link: formData.link,
                    url_web: bannerWeb,
                    url_mobile: bannerMobile,
                    priority: 100,
                    is_enable: isEnable
                });

                if (response.status === HTTP.STATUS.CREATED) {
                    BannerFormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Banner cadastrado com sucesso',
                        toast
                    );

                    BannerFormHelper.reset(formRef);
                    navigate('/banners');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    BannerFormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [bannerMobile, bannerWeb, navigate, isEnable, toast]
    );

    const handleUploadBannerWeb = useCallback((url: string) => {
        setBannerWeb(url);
    }, []);

    const handleUploadBannerMobile = useCallback((url: string) => {
        setBannerMobile(url);
    }, []);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="600"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
          Informações Básicas
                </Text>
                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Box width="32%">
                        <Input name="name" label="Nome" isRequired size="sm" />
                    </Box>
                    <Box width="32%">
                        <Input name="link" label="Link" isRequired size="sm" />
                    </Box>
                    <Box width="32%">
                        <Select name="type" label="Tipo" isRequired size="sm">
                            <option value="BANNER">Banner</option>
                            <option value="SLIDE">Slide</option>
                            <option value="MINI_BANNER">Mini Banner</option>
                        </Select>
                    </Box>
                </Flex>

                <Flex width="100%" mb="16px">
                    <Box fontWeight="500" mr="48px">
                        <Text>Ativar banner?</Text>
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            maxWidth="80px"
                            alignItems="flex-end"
                            mt="8px"
                        >
                            <Checkbox
                                name="is_enable"
                                isChecked={isEnable === false}
                                onChange={() => setEnable(!isEnable)}
                                value="0"
                                mr="16px"
                            >
                                <Text fontSize="14px">Não</Text>
                            </Checkbox>

                            <Checkbox
                                name="is_enable"
                                isChecked={isEnable}
                                onChange={() => setEnable(!isEnable)}
                                value="1"
                                mr="16px"
                            >
                                <Text fontSize="14px">Sim</Text>
                            </Checkbox>
                        </Flex>
                    </Box>
                </Flex>

                <Text>Banner Web</Text>
                <UploadBannerImage
                    defaultImage={bannerWeb}
                    onUploadFunction={handleUploadBannerWeb}
                />

                <Text mt="24px">Banner Mobile</Text>
                <UploadBannerImage
                    defaultImage={bannerMobile}
                    onUploadFunction={handleUploadBannerMobile}
                />

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
          Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default BannersRegister;

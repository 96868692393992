import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { Button, Divider, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';

const InformationsSettingsPage: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();

    const [generalSetting, setGeneralSetting] = useState(null);
    const [isFreeDelivery, setIsFreeDelivery] = useState(false);

    const handleSubmit = useCallback(
        async data => {
            try {
                formRef.current.setErrors({});

                const schemaValidation = Yup.object().shape({
                    name: Yup.string().required('Nome não informado')
                });

                await schemaValidation.validate(data, {
                    abortEarly: false
                });

                const response = await apiGateway.put('/settings/general_settings', {
                    ...data,
                    is_free_delivery: isFreeDelivery
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    toast({
                        title: 'Registro Atualizado',
                        description: 'Informações atualizadas com sucesso',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [isFreeDelivery, toast]
    );

    useEffect(() => {
        changeTitle('Configurações Gerais');

        apiGateway.get('/settings/general_settings').then(response => {
            setGeneralSetting(response.data);
            setIsFreeDelivery(response.data.is_free_delivery);
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column" fontSize="12px">
                {generalSetting !== null && (
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        <Flex
                            width="100%"
                            maxWidth="800px"
                            flexDirection="column"
                            backgroundColor="white"
                            px="24px"
                        >
                            <Flex
                                width="100%"
                                py="16px"
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="16px" color="purple.500">
                      Informações e Contato
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="name"
                                                    label="Nome da Loja (Fantasia)"
                                                    size="sm"
                                                    isRequired
                                                    defaultValue={generalSetting.name}
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="whatsapp"
                                                    label="Whatsapp"
                                                    size="sm"
                                                    defaultValue={generalSetting.whatsapp}
                                                />
                                            </Flex>
                                        </Flex>

                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="document"
                                                    label="CNPJ"
                                                    size="sm"
                                                    defaultValue={generalSetting.document}
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="telephone"
                                                    label="Telefone"
                                                    size="sm"
                                                    defaultValue={generalSetting.telephone}
                                                />
                                            </Flex>
                                        </Flex>

                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="email_contact"
                                                    label="E-mail para contato"
                                                    size="sm"
                                                    defaultValue={generalSetting.email_contact}
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="email_order"
                                                    label="E-mail para pedidos"
                                                    size="sm"
                                                    defaultValue={generalSetting.email_order}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="16px" color="purple.500">
                      Localização
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="zipcode"
                                                    label="CEP"
                                                    size="sm"
                                                    defaultValue={generalSetting.zipcode}
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="state"
                                                    label="Estado"
                                                    size="sm"
                                                    defaultValue={generalSetting.state}
                                                />
                                            </Flex>
                                        </Flex>

                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="city"
                                                    label="Cidade"
                                                    size="sm"
                                                    defaultValue={generalSetting.city}
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="neighborhood"
                                                    label="Bairro"
                                                    size="sm"
                                                    defaultValue={generalSetting.neighborhood}
                                                />
                                            </Flex>
                                        </Flex>

                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="37%">
                                                <Input
                                                    name="street"
                                                    label="Rua"
                                                    size="sm"
                                                    defaultValue={generalSetting.street}
                                                />
                                            </Flex>
                                            <Flex width="22%">
                                                <Input
                                                    name="number"
                                                    label="Número"
                                                    size="sm"
                                                    defaultValue={generalSetting.number}
                                                />
                                            </Flex>
                                            <Flex width="37%">
                                                <Input
                                                    name="complement"
                                                    label="Complemento"
                                                    size="sm"
                                                    defaultValue={generalSetting.complement}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="16px" color="purple.500">
                      Logística
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="min_order_value"
                                                    label="Valor Mínimo de Compra"
                                                    size="sm"
                                                    defaultValue={generalSetting.min_order_value || 0}
                                                    badge="currency"
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Checkbox
                                                    name="is_free_delivery"
                                                    size="sm"
                                                    defaultValue={generalSetting.is_free_delivery}
                                                    isChecked={isFreeDelivery}
                                                    onChange={() => setIsFreeDelivery(!isFreeDelivery)}
                                                >
                                                    <Text>Ativar Entrega Gratís</Text>
                                                </Checkbox>
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="16px" color="purple.500">
                      Redes Sociais
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex width="100%" justifyContent="space-between">
                                            <Flex width="48%">
                                                <Input
                                                    name="instagram"
                                                    label="Instagram"
                                                    size="sm"
                                                    defaultValue={generalSetting.instagram}
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="facebook"
                                                    label="Facebook"
                                                    size="sm"
                                                    defaultValue={generalSetting.facebook}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%">
                                        <Flex width="100%" mt="24px">
                                            <Flex width="100%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    height="48px"
                                                    mt="24px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    borderRadius="2px"
                                                >
                          Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Divider my="0px" />
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Form>
    );
};

export default InformationsSettingsPage;

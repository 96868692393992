import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Flex, Icon, Image, Stack, Text, useDisclosure } from '@chakra-ui/core';
import { MdDoubleArrow } from 'react-icons/md';
import ModalFlorists from '../ModalFlorists';
import ModalDeliverymen from '../ModalDeliverymen';

interface Props {
    order: any;
}

export default function OrderProductionDetails({ order }: Props) {
    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const [products, setProducts] = useState([]);

    const [step, setStep] = useState(() => {
        if(order.delivery_status === 'EM_PRODUCAO'){
            return 'AGUARDANDO_PRODUCAO';
        }

        return order.delivery_status;
    });

    const handleChangeStep = useCallback(() => {
        setStep((step: string) => {
            if(step === 'AGUARDANDO_PRODUCAO') {
                onOpen1();
            }
            
            if(step === 'EM_PRODUCAO') {
                onOpen2();
                return 'SAIU_PARA_ENTREGA';
            }

            return 'EM_PRODUCAO';
        });
    }, []);

    useEffect(() => {
        if(order) setProducts(order.products); 
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" justifyContent="flex-end">
                <Flex  width="50%" flexDirection="column" justifyContent="flex-end">
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing="4" fontSize="14px">
                        <Text 
                            backgroundColor={step === 'AGUARDANDO_PRODUCAO'  ? 'green.500' : 'none'}
                            color={step === 'AGUARDANDO_PRODUCAO' ? 'white' : 'gray.300'}
                            px="16px" 
                            borderRadius="2px"
                        >
                            {step === 'AGUARDANDO_PRODUCAO' ? 'AGUARDANDO PRODUÇÃO' : 'Aguardando Produção'}
                        </Text>
                        <Text>|</Text>
                        <Text 
                            backgroundColor={step === 'EM_PRODUCAO'  ? 'green.500' : 'none'}
                            color={step === 'EM_PRODUCAO' ? 'white' : 'gray.300'}
                            px="16px" 
                            borderRadius="2px"
                        >
                            {step === 'EM_PRODUCAO' ? 'EM PRODUÇÃO' : 'Em produção'}
                        </Text>
                        <Text>|</Text>
                        <Text 
                            backgroundColor={step === 'SAIU_PARA_ENTREGA'  ? 'green.500' : 'none'}
                            color={step === 'SAIU_PARA_ENTREGA' ? 'white' : 'gray.300'}
                            px="16px" 
                            borderRadius="2px"
                        >
                            {step === 'SAIU_PARA_ENTREGA' ? 'SAIU PARA ENTREGA' : 'Saiu para entrega'}
                        </Text>
                        <Flex 
                            p="2px" 
                            backgroundColor="red.500" 
                            borderRadius="2px"
                            cursor="pointer"
                            onClick={() => handleChangeStep()}
                            title="Avançar Etapa"
                        >
                            <Icon as={MdDoubleArrow} color="white" width="28px" fontSize="20px" />
                        </Flex>
                    </Stack>
                    <Divider />
                </Flex>
            </Flex>
            
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" height="auto" overflow="hidden" flexDirection="column">
                    <Flex ml="384px" width="100%" flexDirection="column">
                        <Text fontWeight="500" color="green.500">{`Pedido ${order.code}`}</Text>

                        <Flex flexDirection="column">
                            {order.details.identify_sender ? order.buyer.name : 'Anônimo'}
                        </Flex>
                    </Flex>

                    {products.map((product, index) => (
                        <Flex  key={product.id} width="100%" mb="24px">
                            <Flex width="360px" flexDirection="column" justifyContent="center" alignItems="center">
                                <Image src={product.cover.url} width={index === 0 ?  '100%' : '64%'} /> 
                            </Flex> 

                            <Flex width="calc(100% - 360px)" flexDirection="column" px="24px">
                                <Flex width="100%" fontSize="14px" mt="24px" flexDirection="column">
                                    <Text fontWeight="500" fontSize="16PX" color="green.500">Composição</Text>
                                
                                    <Flex key={product.id} width="100%" flexDirection="column">
                                        <Text  fontWeight="500" fontSize="16PX">
                                            {`Cód: ${product.code_sku} - ${product.name}`}
                                        </Text>
                                        <Flex width="100%" whiteSpace="pre-wrap">
                                            <Text>{product.description}</Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex> 
                        </Flex>
                    ))}

                    <Flex ml="384px"  width="100%" fontSize="14px" mt="auto" mb="24px" flexDirection="column">
                        <Text fontWeight="500" fontSize="16PX">Mensagem:</Text>
                        <Flex width="100%" whiteSpace="pre-wrap">
                            {order.details.card_message}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <ModalFlorists isOpen={isOpen1} onClose={onClose1} orderId={order.id} />
            <ModalDeliverymen isOpen={isOpen2} onClose={onClose2} orderId={order.id} />
        </Flex>
    );
}
import React, { useCallback } from 'react';

import { Collapse, Flex, Text } from '@chakra-ui/core';

import InputCurrency from '../../../../../../components/Form/InputCurrency';
import NumberUtils from '../../../../../../shared/utils/NumberUtils';
import CollapseCheckoutBox from '../../../../components/CollapseCheckoutBox';
import { useCashier } from '../../../../hooks/useCashier';

const DescountsFeeSection: React.FC = () => {
    const { getStateBox, collapseBox, addDescountValue, addTaxValue } =
    useCashier();

    const handleAddDescount = useCallback(value => {
        addDescountValue(NumberUtils.formatNumber(value));
    }, []);

    const handleAddTax = useCallback(value => {
        addTaxValue(NumberUtils.formatNumber(value));
    }, []);

    return (
        <Flex width={'100%'} flexDirection="column">
            <CollapseCheckoutBox
                title="Descontos e Acréscimos"
                isCollapsed={getStateBox('DESCOUNT')}
                onCollapse={() => collapseBox('DESCOUNT')}
            />

            <Collapse
                mt="4px"
                isOpen={getStateBox('DESCOUNT')}
                display="flex"
                flexDirection="column"
                mb="8px"
            >
                <Flex width="100%" flexDirection={'column'}>
                    <Flex width="100%" alignItems="center" px="8px">
                        <Text fontSize="14px" width="88px" mr="24px">
              Desconto
                        </Text>

                        <InputCurrency
                            width="100px"
                            name="discount_value"
                            backgroundColor="gray.500"
                            placeholder="Valor do desconto"
                            borderRadius="2px"
                            size="sm"
                            mb="4px"
                            defaultValue={0}
                            onBlur={e => handleAddDescount(e.currentTarget.value)}
                        />
                    </Flex>

                    <Flex width="100%" alignItems="center" px="8px">
                        <Text fontSize="14px" width="88px" mr="24px">
              Acréscimo
                        </Text>

                        <InputCurrency
                            width="100px"
                            name="tax_value"
                            backgroundColor="gray.500"
                            placeholder="Valor do acréscimo"
                            borderRadius="2px"
                            size="sm"
                            mb="0px"
                            defaultValue={0}
                            onBlur={e => handleAddTax(e.currentTarget.value)}
                        />
                    </Flex>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export default DescountsFeeSection;

import React, { useEffect, useState } from 'react';
import { Divider, Flex, Text, Image, Badge } from '@chakra-ui/core';
import { useLayout } from '../../layouts/default';
import Form from '../../components/Form';
import logoWhats from '../../assets/images/logo_whatsapp.png';
import apiGateway from '../../shared/services/apiGateway';
import { HTTP } from '../../shared/constants';


const NotificationSettings: React.FC = () => {
    const { changeTitle } = useLayout();
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        changeTitle('Notificações');
        apiGateway.get('/settings/integrations')
            .then((response) => {
                const { status, data } = response;
                if(status === HTTP.STATUS.SUCCESS) {
                    setSettings(data);
                    console.log(data);
                }
            });
    }, []);

    return (
        <Form onSubmit={() => null}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex
                        width="100%"
                        flexDirection="column"
                        backgroundColor="white"
                        px="24px"
                    >
                        <Flex
                            width="100%"
                            py="16px"
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex
                                    width="50%"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                                       Funcionalidades
                                    </Text>
                                </Flex>
                            </Flex>

                            <Divider my="0px" />
                            <Flex
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                                mt="24px"
                            >
                                <Flex width="100%" maxWidth="100%" overflowX="auto">
                                    <Flex width="100%">
                                        <Flex
                                            width="100%"
                                            maxWidth="192px"
                                            p="8px"
                                            border="1px solid"
                                            borderColor="gray.200"
                                            justifyContent="space-between"
                                            flexDirection="column"
                                            mr="24px"
                                        >
                                            <Flex
                                                width="100%"
                                                alignItems="center"
                                                justifyContent="center"
                                                height="100%"
                                                py="8px"
                                                backgroundColor="white"
                                            >
                                                <Image width="60%" objectFit="fill" src={logoWhats} />
                                            </Flex>
                                                
                                            
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                                mt="16px"
                                            >
                                                <Flex
                                                    width="100%"
                                                    flexDirection="column"
                                                    fontSize="12px"
                                                    height="100%"
                                                >
                                                    <Badge
                                                        width="100%"
                                                        textAlign="center"
                                                        backgroundColor={
                                                            settings?.whatsapp ? 'green.500' : 'pink.500'
                                                        }
                                                        color="white"
                                                        py="7px"
                                                    >
                                                        {settings?.whatsapp ? 'Habilitado' : 'Desabilitado'}
                                                    </Badge>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex flexDirection="column">
                                            <Text color="green.500">Notificação Via Whatsapp</Text>
                                            <Text color="gray.600">Envie notificações para seus clientes quando o pedido for entregue.</Text>
                                            {!settings?.whatsapp && (
                                                <Flex 
                                                    backgroundColor="yellow.100"
                                                    border="1px solid" 
                                                    borderColor="yellow.500" 
                                                    flexDirection="column" 
                                                    mt="auto"
                                                    p="8px"
                                                >
                                                    <Text color="gray.600">Atenção</Text>
                                                    <Text color="gray.600" fontSize="14px">Para ativar esse serviço entre em contato com nosso suporte, essa funcionalidade tem custo adicional.</Text>
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider my="0px" />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default NotificationSettings;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import TextArea from '../../../../components/Form/TextArea';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import CategoryHelper from '../../helpers/CategoryHelper';

const CategoriesEdit: React.FC = () => {
    const [category, setCategory] = useState(undefined);

    const [showInHeader, setShowInHeader] = useState(false);
    const [showInFooter, setShowInFooter] = useState(false);
    const navigate = useNavigate();

    const [listCategories, setListCategories] = useState([]);
    const [metaDescriptionLength, setMetaDescriptionLength] = useState(0);

    const { id } = useParams() as any;

    const formRef = useRef<FormHandles>();
    const toast = useToast();

    const handleChangeName = useCallback((inputName: HTMLInputElement) => {
        const name = inputName.value;

        formRef.current.getFieldRef('title').value = name;
        formRef.current.getFieldRef('slug').value = name;
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const editCategoryDTO = await CategoryHelper.makeValidCategoryDataToSend(
                    {
                        formData,
                        show_in_header: showInHeader,
                        show_in_footer: showInFooter
                    }
                );

                apiGateway.put(`/categories/${id}`, editCategoryDTO).then(response => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        FormHelper.reset(formRef);

                        toast({
                            title: 'Edição efetuada',
                            description: 'Registro editado com succeso',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });

                        navigate('/categories');

                        CategoryHelper.loadCategories().then(categories =>
                            setListCategories(categories)
                        );
                    }
                });
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [navigate, id, showInFooter, showInHeader, toast]
    );

    useEffect(() => {
        apiGateway.get(`/categories/${id}`).then(response => {
            setCategory(response.data);

            const {
                show_in_header,
                show_in_footer,
                meta_description
            } = response.data as any;

            setShowInHeader(show_in_header);
            setShowInFooter(show_in_footer);
            setMetaDescriptionLength(meta_description?.length);
        });

        CategoryHelper.loadCategories().then(categories =>
            setListCategories(categories)
        );
    }, [id]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            {category && (
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
            Informações Básicas
                    </Text>
                    <Flex
                        minWidth="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt="8px"
                    >
                        <Box width="48%">
                            <Input
                                name="name"
                                label="Nome da Categoria"
                                isRequired
                                size="sm"
                                onChange={e => handleChangeName(e.currentTarget)}
                                defaultValue={category.name}
                            />
                        </Box>
                        <Box width="48%">
                            <Select
                                name="parent_id"
                                label="Categoria Pai"
                                isRequired
                                size="sm"
                            >
                                <option value="">Sem categoria pai</option>
                                {listCategories.map(item => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                        selected={item.id === category.parent_id}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </Box>

                        <Flex width="100%">
                            <Box fontWeight="500" mr="48px">
                                <Text>Mostrar no cabeçalho?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="show_in_header"
                                        isChecked={showInHeader === false}
                                        onChange={() => setShowInHeader(!showInHeader)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="show_in_header"
                                        isChecked={showInHeader}
                                        onChange={() => setShowInHeader(!showInHeader)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>

                            <Box fontWeight="500" mr="48px">
                                <Text>Mostrar no rodapé?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="show_in_footer"
                                        isChecked={showInFooter === false}
                                        onChange={() => setShowInFooter(!showInFooter)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="show_in_footer"
                                        isChecked={showInFooter}
                                        onChange={() => setShowInFooter(!showInFooter)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                    <Text color="purple.500" fontSize="20px" mt="16px">
            SEO
                    </Text>
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        mt="8px"
                    >
                        <Box width="48%">
                            <Input
                                name="title"
                                label="Titulo da página"
                                isRequired
                                size="sm"
                                defaultValue={category.title}
                            />
                        </Box>

                        <Box width="48%">
                            <Input
                                name="slug"
                                label="Slug"
                                isRequired
                                size="sm"
                                mb="0px"
                                defaultValue={category.slug}
                            />
                            <Flex width="100%" fontSize="12px">
                                <Text>
                                    Exemplo da url na loja:
                                    www.minhaloja.com.br/categorias/meu-slug
                                </Text>
                            </Flex>
                        </Box>

                        <Flex width="100%">
                            <TextArea
                                onBlur={e =>
                                    setMetaDescriptionLength(e.currentTarget.value.length)
                                }
                                name="meta_description"
                                label="Meta Description"
                                mb="0px"
                                isRequired
                                defaultValue={category.meta_description}
                            />
                        </Flex>
                        <Flex width="100%" fontSize="12px">
                            <Text mr="16px">{`Num. caracteres: ${metaDescriptionLength}`}</Text>
                            <Text>
                Para bons resultados nas buscas do google recomendamos que sua
                meta description tenha aproximadamente 230 caracteres.
                            </Text>
                        </Flex>
                    </Flex>
                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
            Editar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default CategoriesEdit;

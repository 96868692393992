import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiDollarCircle, BiToggleLeft, BiToggleRight } from 'react-icons/bi';
import { FiStar, FiTrash2, FiEdit2, FiSearch, FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import {
    Badge,
    Divider,
    Flex,
    PseudoBox,
    Text,
    Box,
    Image,
    Checkbox,
    Icon,
    useDisclosure,
    useToast
} from '@chakra-ui/core';

import withoutImage from '../../../../assets/without-image.png';
import Button from '../../../../components/Button';
import InputSearch from '../../../../components/Form/InputSearch';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import ModalPrices from '../../components/ModalPrices';
import ModalDeleteProduct from './ModalDeleteProduct';
import ModalViewProduct from './ModalViewProduct';

interface IProduct {
  id: string;
  code_sku: string;
  name: string;
  price_sale: number;
  price_promotional: number;
  is_enable: boolean;
  cover: {
    thumb_url: string;
  };
}

const Products: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [statusFilter, setStatusFilter] = useState(undefined);
    const [selectedProductsIds, setSelectedProductsIds] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(true);
    const [viewProductId, setViewProductId] = useState(null);

    const toast = useToast();
    const inputQueryRef = useRef<HTMLInputElement>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2
    } = useDisclosure();
    const {
        isOpen: isOpenDP,
        onOpen: onOpenDP,
        onClose: onCloseDP
    } = useDisclosure();

    const handleHighlightProducts = useCallback(
        (productsIds: string[]) => {
            apiGateway
                .put('/products/highlight', {
                    ids: productsIds
                })
                .then(response => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        apiGateway
                            .get(`/products?is_enable=${statusFilter}`)
                            .then(response2 => {
                                setProducts(response2.data);
                            });

                        setSelectedProductsIds([]);
                    }
                });
        },
        [statusFilter]
    );

    const handleDeleteProducts = useCallback(
        (productsIds: string[]) => {
            onOpenDP();

            setSelectedProductsIds(productsIds);
        },
        [onOpenDP]
    );

    const deleteProducts = useCallback(() => { 
        apiGateway
            .delete('/products', { 
                params: {
                    ids: selectedProductsIds
                }
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    const updatedListProduct = products.filter(
                        product => !selectedProductsIds.includes(product.id)
                    );

                    setProducts(updatedListProduct);

                    setSelectedProductsIds([]);

                    onCloseDP();

                    toast({
                        title: 'Operação realizada',
                        description: 'Exclusão realizada com sucesso.',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [selectedProductsIds, products, onCloseDP, toast]);

    const handleSearchProducts = useCallback(() => {
        const query = inputQueryRef?.current?.value;
        if(query.length >= 3){
            apiGateway
                .get(`/products?query=${query}&is_enable=${statusFilter}`)
                .then(response => {
                    setProducts(response.data);
                });
        }
    }, [statusFilter]);

    const handleChangeStatusFilter = useCallback(
        (value: 0 | 1 | 2 | undefined) => {
            setStatusFilter(value);

            apiGateway.get(`/products?is_enable=${value}`).then(response => {
                setProducts(response.data);
            });
        }, []);

    const handleViewProduct = useCallback((productId: string) => {
        setViewProductId(productId);
        onOpen2();
    },[onOpen2]);

    const handleChangeSelectedProductsIds = useCallback((value: string) => {
        setSelectedProductsIds(oldState => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter(item => item !== value);
            }

            return [...list];
        });
    }, []);

    const ProductRow = ({ style, index, data }: any) => {
        return (
            <ListRow style={style} index={index}>
                <Flex width="100%" justifyContent="center" maxWidth="24px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onClick={() => handleChangeSelectedProductsIds(data[index].id)}
                        value={data[index].id}
                        borderColor="purple.500"
                        isChecked={selectedProductsIds.includes(data[index].id)}
                    />
                </Flex>
                <Flex width="100%" justifyContent="center" maxWidth="88px">
                    <Flex width="80px" height="80px" alignItems="center">
                        <Image
                            src={data[index].cover?.thumb_url || withoutImage}
                            alt="teste"
                        />
                    </Flex>
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].code_sku}</Text>
                </Flex>
                <Flex width="100%" justifyContent="center">
                    <Text textAlign="center" lineHeight="16px">
                        {data[index].name}
                    </Text>
                </Flex>

                <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="96px"
                >
                    <Badge
                        width="56px"
                        textAlign="center"
                        backgroundColor={
                            data[index].is_highlight ? 'yellow.500' : 'gray.800'
                        }
                        color="white"
                        py="4px"
                    >
                        <Text fontSize="10px">
                            {data[index].is_highlight ? 'Sim' : 'Não'}
                        </Text>
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>
                        {CurrencyUtils.numberToCurrency(data[index].price_purchase)}
                    </Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{CurrencyUtils.numberToCurrency(data[index].price_sale)}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="96px">
                    <Badge
                        width="80px"
                        textAlign="center"
                        backgroundColor={data[index].is_enable ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                        fontSize="10px"
                    >
                        {data[index].is_enable ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <Link to={`/products/edit/${data[index].id}`}>
                        <PseudoBox
                            title="Editar Produto"
                            p="8px"
                            backgroundColor="purple.500"
                            color="white"
                            borderRadius="2px"
                            cursor="pointer"
                        >
                            <FiEdit2 size={14} />
                        </PseudoBox>
                    </Link>

                    <PseudoBox
                        ml="8px"
                        title="Visualizar Produto"
                        p="8px"
                        backgroundColor="green.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleViewProduct(data[index].id)}
                    >
                        <FiEye size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    const handleSelectAll = useCallback(() => {
        if (isSelectedAll) {
            setSelectedProductsIds(products.map(item => item.id));
        } else {
            setSelectedProductsIds([]);
        }

        setIsSelectedAll(oldState => !oldState);
    }, [isSelectedAll, products]);

    const handleSetIsEnable = useCallback(
        (value: boolean) => {
            apiGateway
                .put('/products/enable_or_disable', {
                    is_enable: value,
                    ids: selectedProductsIds
                })
                .then(() => {
                    apiGateway
                        .get(`/products?is_enable=${statusFilter}`)
                        .then(response => {
                            setProducts(response.data);
                        });
                });
        },
        [selectedProductsIds, statusFilter]
    );

    useEffect(() => {
        changeTitle('Produtos');

        apiGateway.get(`/products?is_enable=${statusFilter}`).then(response => {
            setProducts(response.data);
        });
    }, [changeTitle, statusFilter]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="184px"
                    borderRadius="2px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    onClick={() => navigate('/products/register')}
                >
          Novo Produto
                </Button>

                <Button
                    backgroundColor="blue.500"
                    width="216px"
                    borderRadius="2px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    onClick={() => handleSelectAll()}
                    _hover={{
                        backgroundColor: 'blue.500'
                    }}
                >
                    {isSelectedAll ? 'Selecionar Todos' : 'Desmarcar Todos'}
                </Button>

                <Button
                    backgroundColor="yellow.500"
                    width="40px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Destacar Produtos"
                    _hover={{
                        backgroundColor: 'yellow.500'
                    }}
                    onClick={() => handleHighlightProducts(selectedProductsIds)}
                >
                    <FiStar size={18} />
                </Button>

                <Button
                    backgroundColor="gray.800"
                    width="56px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Modificar Preços"
                    _hover={{
                        backgroundColor: 'gray.800'
                    }}
                    onClick={() => onOpen()}
                >
                    <BiDollarCircle size={22} />
                </Button>

                <Button
                    backgroundColor="orange.500"
                    width="56px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    px="8px"
                    title="Desativar Selecionados"
                    _hover={{
                        backgroundColor: 'orange.500'
                    }}
                    onClick={() => handleSetIsEnable(false)}
                    color="white"
                >
                    <Icon as={BiToggleLeft} size="24px" />
                </Button>

                <Button
                    backgroundColor="green.500"
                    width="56px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Ativar Selecionados"
                    _hover={{
                        backgroundColor: 'green.500'
                    }}
                    onClick={() => handleSetIsEnable(true)}
                    color="white"
                >
                    <Icon as={BiToggleRight} size="24px" />
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Excluir Produtos"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteProducts(selectedProductsIds)}
                >
                    <FiTrash2 size={20} />
                </Button>

                <Box ml="auto" width="100%">
                    <InputSearch
                        ref={inputQueryRef}
                        name="query"
                        placeholder="Pesquisar por nome do produto ou código"
                        onEnterPress={() => handleSearchProducts()}
                        onKeyUp={() => handleSearchProducts()}
                        size="sm"
                    />
                </Box>

                <Button
                    backgroundColor="green.500"
                    width="40px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Pesquisar Produtos"
                    _hover={{
                        backgroundColor: 'purple.500'
                    }}
                    onClick={() => handleSearchProducts()}
                >
                    <FiSearch size={18} />
                </Button>
            </Flex>
            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="18px" color="purple.500">
                Listagem
                            </Text>
                        </Flex>
                        <Flex width="50%" justifyContent="flex-end" alignItems="center">
                            <Text mr="16px" fontSize="12px">
                Mostrar:
                            </Text>
                            <Flex
                                width="80px"
                                py="4px"
                                backgroundColor={
                                    statusFilter === undefined ? 'purple.500' : 'white'
                                }
                                color={statusFilter === undefined ? 'white' : 'purple'}
                                border="1px solid"
                                borderColor="purple.500"
                                justifyContent="center"
                                fontSize="12px"
                                mr="8px"
                                cursor="pointer"
                                onClick={() => handleChangeStatusFilter(undefined)}
                            >
                                <Text>Todos</Text>
                            </Flex>
                            <Flex
                                width="80px"
                                py="4px"
                                backgroundColor={statusFilter === 2 ? 'purple.500' : 'white'}
                                color={statusFilter === 2 ? 'white' : 'purple'}
                                border="1px solid"
                                borderColor="purple.500"
                                justifyContent="center"
                                fontSize="12px"
                                mr="8px"
                                cursor="pointer"
                                onClick={() => handleChangeStatusFilter(2)}
                            >
                                <Text>Destaques</Text>
                            </Flex>
                            <Flex
                                width="80px"
                                py="4px"
                                backgroundColor={statusFilter === 1 ? 'purple.500' : 'white'}
                                color={statusFilter === 1 ? 'white' : 'purple'}
                                border="1px solid"
                                borderColor="purple.500"
                                justifyContent="center"
                                fontSize="12px"
                                mr="8px"
                                cursor="pointer"
                                onClick={() => handleChangeStatusFilter(1)}
                            >
                                <Text>Ativos</Text>
                            </Flex>

                            <Flex
                                width="80px"
                                py="4px"
                                backgroundColor={statusFilter === 0 ? 'purple.500' : 'white'}
                                color={statusFilter === 0 ? 'white' : 'purple'}
                                border="1px solid"
                                borderColor="purple.500"
                                justifyContent="center"
                                fontSize="12px"
                                cursor="pointer"
                                onClick={() => handleChangeStatusFilter(0)}
                            >
                                <Text>Inativos</Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="12px"
                        maxWidth="100%"
                        textTransform="uppercase"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="24px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="88px">
                            <Text>Capa</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Código</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text>Em destaque</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Valor de Custo</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Valor Venda</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="96px">
                            <Text>Situação</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    <ModalPrices
                        isOpen={isOpen}
                        onClose={onClose}
                        productsIds={selectedProductsIds}
                    />

                    <ModalDeleteProduct
                        deleteProducts={deleteProducts}
                        isOpen={isOpenDP}
                        onClose={onCloseDP}
                    />

                    <ModalViewProduct
                        productId={viewProductId}
                        isOpen={isOpen2}
                        onClose={onClose2}
                    />

                    {products.length > 0 && (
                        <List item={products} row={ProductRow} itemHeight={96} />
                    )}

                    {products.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Products;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Flex, Text } from '@chakra-ui/core';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';
import { HTTP } from '../../../../shared/constants';
import OrderProductionDetails from './OrderDetails';

const OrdersProduction: React.FC = () => {
    const { changeTitle } = useLayout();
    const [orders, setOrders] = useState([]);

    const ordersMorning = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Manhã' || order.delivery_hour === 'Manha');
    }, [orders]);

    const ordersEvening = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Tarde');
    }, [orders]);

    const ordersNight = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Noite');
    }, [orders]);

    const loadData = useCallback(() => {
        apiGateway.get('/orders/production')
            .then((response) => {
                const { status, data } = response;
                if(status === HTTP.STATUS.SUCCESS)
                    setOrders(data);
            });
    }, []);

    useEffect(() => {
        changeTitle('Pedidos para Produção');
        loadData();
        const interval = setInterval(() => {
            loadData();
        },  3600000);

        return () => clearInterval(interval);
    }, []);

    return(
        <Flex width="100%" 
            height="100%" 
            flexDirection="column" 
            backgroundColor="white"
            padding="24px"
        >
            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Manhã</Text>
            </Flex>

            {ordersMorning.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}

            {ordersMorning.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da manhã</Text>
                </Flex>
            )}

            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Tarde</Text>
            </Flex>

            {ordersEvening.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}

            {ordersEvening.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da tarde</Text>
                </Flex>
            )}

            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Noite</Text>
            </Flex>

            {ordersNight.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}
            
            {ordersNight.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da noite</Text>
                </Flex>
            )}
        </Flex>
    );
};

export default OrdersProduction;

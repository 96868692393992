import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

import { Divider, Flex, Text, Image, Box } from '@chakra-ui/core';

import logoColorful from '../../../../assets/logo-colorful.png';
import { HTTP } from '../../../../shared/constants';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';
import DateUtils from '../../../../shared/utils/DateUtils';
import TextUtils from '../../../../shared/utils/TextUtils';

interface IProps {
  id: string;
}

const OrderPrint: React.FC<IProps> = ({ id }) => {
    const [order, setOrder] = useState(null);

    const { store } = useAuth();

    useEffect(() => {
        apiGateway.get(`/orders/${id}`).then(response => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrder(response.data);
            }
        });
    }, [id]);

    return (
        order && (
            <Flex
                width="100%"
                height="100%"
                backgroundColor="white"
                flexDirection="column"
            >
                <Flex
                    flexWrap="wrap"
                    border="2px dashed"
                    borderColor="gray.700"
                    p="8px"
                >
                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="50%" flexDirection="column" fontSize="14px">
                            <Text as="h1" color="blue.700" fontSize="16px" mb="8px">
                                {`Pedido - ${order.code}`}
                            </Text>
                            <Text />
                            <Text>{`Forma de Pagamento: ${order.payment_method.name}`}</Text>
                            <Text>
                                {`Status de Pagamento: ${TextUtils.convertStatusPayment(
                                    order.payment_status
                                )}`}
                            </Text>
                            {order.florist && (
                                <Text> 
                                    {`Florista: ${order.florist.name || order.florist.email}`}
                                </Text>
                            )}
                            {order.seller && (
                                <Text> 
                                    {`Vendedor: ${order.seller.name || order.seller.email }`}
                                </Text>
                            )}
                            {order.deliveryman && (
                                <Text> 
                                    {`Entregador: ${order.deliveryman.name}`}
                                </Text>
                            )}

                            <Flex
                                width="100%"
                                flexDirection="column"
                                fontSize="14px"
                                mt="16px"
                            >
                                <Text color="blue.700" fontSize="16px" mb="4px">
                  Dados de Entrega
                                </Text>

                                <Flex>
                                    <Text mr="8px">Data de Entrega: </Text>
                                    <Text>{DateUtils.format(order.delivery_date)}</Text>
                                </Flex>

                                <Flex>
                                    <Text mr="8px">Hora: </Text>
                                    <Text>{order.delivery_hour}</Text>
                                </Flex>

                                <Flex flexDirection={'column'}>
                                    <Text>{`Cidade: ${
                                        order?.delivery_address?.city || 'Não informado'
                                    }`}</Text>
                                    <Text>{`Bairro: ${
                                        order?.delivery_address?.neighborhood || 'Não informado'
                                    }`}</Text>
                                    <Text>{`Rua: ${
                                        order?.delivery_address?.street || 'Não informado'
                                    }, ${order?.delivery_address?.number || ''}`}</Text>
                                    <Text>
                                        {`Complemento: ${
                                            order?.delivery_address?.complement
                                                ? order.delivery_address.complement
                                                : 'Não informado'
                                        }`}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex width="50%" mt="16px" flexDirection="column">
                            <Flex width="100%" flexDirection="column" fontSize="14px">
                                <Text color="blue.700" fontSize="16px" mb="4px">
                  Comprador
                                </Text>
                                <Text>{`Nome: ${order.buyer.name}`}</Text>
                                <Text>{`CPF: ${TextUtils.maskCPF(
                                    order?.buyer?.document || ''
                                )}`}</Text>
                                <Text>
                                    {`Telefone: ${TextUtils.mask(
                                        order.buyer.telephone,
                                        '+#############'
                                    )}`}
                                    <Text>{`E-mail: ${order.buyer.email}`}</Text>
                                </Text>
                            </Flex>

                            <Text whiteSpace="pre-wrap" mt="16px">
                                {`Observações: ${
                                    order.details.observations
                                        ? order.details.observations
                                        : 'Não Informado'
                                }`}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex width="100%">
                        <Flex width="100%" mt="16px" flexDirection="column">
                            <Flex width="100%" flexDirection="column" fontSize="14px">
                                <Flex width="100%" flexDirection="column">
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        fontSize="14px"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent="center"
                                            maxWidth="40px"
                                            mr="40px"
                                        >
                                            <Text>Foto</Text>
                                        </Flex>

                                        <Flex width="100%" justifyContent="center" maxWidth="80px">
                                            <Text>Cód.</Text>
                                        </Flex>
                                        <Flex width="100%" justifyContent="center">
                                            <Text>Nome</Text>
                                        </Flex>
                                        <Flex width="100%" justifyContent="center" maxWidth="80px">
                                            <Text>QTD</Text>
                                        </Flex>
                                    </Flex>
                                    <Divider width="100%" my="4px" />

                                    {order.products.map(product => (
                                        <Flex key={product.id} width="100%" flexDirection="column">
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                                fontSize="12px"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    maxWidth="40px"
                                                    mr="40px"
                                                >
                                                    <Image src={product.cover.thumb_url} />
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    maxWidth="80px"
                                                >
                                                    <Text>{product.code_sku}</Text>
                                                </Flex>
                                                <Flex width="100%" justifyContent="center">
                                                    <Text>{product.name}</Text>
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    maxWidth="80px"
                                                >
                                                    <Text>{product.quantity}</Text>
                                                </Flex>
                                            </Flex>

                                            <Divider width="100%" my="2px" />
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    flexWrap="wrap"
                    border="2px dashed"
                    borderTop="none"
                    borderColor="gray.700"
                    p="8px"
                >
                    <Flex width="100%">
                        <Flex width="100%" flexDirection="column" fontSize="14px">
                            <Text as="h1" color="blue.700" fontSize="16px" mb="8px">
                                {`Pedido - ${order.code}`}
                            </Text>

                            <Text color="blue.700" fontSize="16px" mb="8px">
                Dados de Entrega
                            </Text>

                            <Flex flexDirection={'column'}>
                                <Text>{`Cidade: ${
                                    order?.delivery_address?.city || 'Não informado'
                                }`}</Text>
                                <Text>{`Bairro: ${
                                    order?.delivery_address?.neighborhood || 'Não informado'
                                }`}</Text>
                                <Text>{`Rua: ${
                                    order?.delivery_address?.street || 'Não informado'
                                }, ${order?.delivery_address?.number || ''}`}</Text>
                                <Text>
                                    {`Complemento: ${
                                        order?.delivery_address?.complement
                                            ? order.delivery_address.complement
                                            : 'Não informado'
                                    }`}
                                </Text>
                            </Flex>

                            <Flex>
                                <Text mr="8px">Data de Entrega: </Text>
                                <Text>{DateUtils.format(order.delivery_date)}</Text>
                            </Flex>

                            <Flex>
                                <Text mr="8px">Hora: </Text>
                                <Text>{order.delivery_hour}</Text>
                            </Flex>

                            <Text
                                color="black"
                                fontSize="16px"
                                mt="24px"
                                whiteSpace="pre-wrap"
                            >
                                {`Obs: ${order.details.observations || 'Não informada'}`}
                            </Text>
                        </Flex>

                        <Flex width="50%" flexDirection="column" fontSize="14px">
                            <Text color="blue.700" fontSize="16px" mb="8px">
                Dados do Destinatário
                            </Text>
                            <Text>{`Nome: ${order.details.destinatary_name}`}</Text>
                            <Text>
                                {`Telefone: ${
                                    order.details.destinatary_telephone
                                        ? TextUtils.mask(
                                            order.details.destinatary_telephone,
                                            '+#############'
                                        )
                                        : 'Não informado'
                                }`}
                            </Text>

                            <Flex mt="24px">
                                <QRCode
                                    size={100}
                                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                    value={`https://dashboard.eflorista.com.br/delivery/${store}/${order.id}`}
                                    viewBox={'0 0 160 160'}
                                />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Divider width="100%" my="16px" />

                    <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="space-between" fontSize="14px">
                            <Flex
                                width="100%"
                                justifyContent="center"
                                maxWidth="40px"
                                mr="40px"
                            >
                                <Text>Foto</Text>
                            </Flex>

                            <Flex width="100%" justifyContent="center" maxWidth="80px">
                                <Text>Cód.</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Nome</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="80px">
                                <Text>QTD</Text>
                            </Flex>
                        </Flex>
                        <Divider width="100%" my="4px" />

                        {order.products.map(product => (
                            <Flex key={product.id} width="100%" flexDirection="column">
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    fontSize="12px"
                                    alignItems="center"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        maxWidth="40px"
                                        mr="40px"
                                    >
                                        <Image src={product.cover.thumb_url} />
                                    </Flex>
                                    <Flex width="100%" justifyContent="center" maxWidth="80px">
                                        <Text>{product.code_sku}</Text>
                                    </Flex>
                                    <Flex width="100%" justifyContent="center">
                                        <Text>{product.name}</Text>
                                    </Flex>
                                    <Flex width="100%" justifyContent="center" maxWidth="80px">
                                        <Text>{product.quantity}</Text>
                                    </Flex>
                                </Flex>

                                <Divider width="100%" my="2px" />
                            </Flex>
                        ))}
                    </Flex>

                    <Flex width="100%" mt="24px">
                        <Flex width="100%" flexDirection="column">
                            <Text color="blue.700" fontSize="16px" mb="8px">
                Mensagem a ser impressa no cartão
                            </Text>
                            <Text fontSize="12px" whiteSpace="pre-wrap">
                                {order.details.card_message
                                    ? order.details.card_message
                                    : 'Não informada'}
                            </Text>

                            <Flex fontSize="14px" mt="24px">
                                <Text mr="8px">Identificar remetente?</Text>
                                <Text>{order.details.identify_sender ? 'Sim' : 'Não'}</Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="center">
                        <Flex
                            width="30%"
                            height="100%"
                            justifyContent="center"
                            alignItems="center"
                            mb="4px"
                        >
                            <Box width="100%">
                                <Image src={logoColorful} opacity={0.2} />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        )
    );
};

export default OrderPrint;

export default class CategoryMap {
    static formDataToCreateDTO(
        data
    ): any {
        return {
            name: data.name,
            parent_id: data.parent_id,
            show_in_header: data.show_in_header,
            show_in_footer: data.show_in_footer,
            title: data.title,
            slug: data.slug,
            meta_description: data.meta_description
        };
    }
}

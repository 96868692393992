import { subMonths } from 'date-fns';
import React from 'react';
import DayPicker, { DayPickerProps } from 'react-day-picker';

import { Container } from './styles';

interface IProps extends DayPickerProps {
  selectedDate: Date;
  handleDateChange: any;
  handleMonthChange: any;
  isErrored: boolean;
}

const Calender: React.FC<IProps> = ({
    selectedDate,
    handleDateChange,
    handleMonthChange,
    isErrored = false,
    ...rest
}) => (
    <Container isErrored={isErrored}>
        <DayPicker
            weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
            fromMonth={subMonths(new Date(), 6)}
            disabledDays={[{ daysOfWeek: [] }]}
            modifiers={{
                available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
            }}
            onMonthChange={handleMonthChange}
            selectedDays={selectedDate}
            onDayClick={handleDateChange}
            months={[
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ]}
            {...rest}
        />
    </Container>
);

export default Calender;
